import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isPWA } from '../config';

const options = {
	enabled: true,
	chatButtonSetting: {
		backgroundColor: '#4dc247',
		ctaText: 'Ai întrebări?',
		borderRadius: '25',
		marginLeft: '0',
		marginBottom: isPWA ? '86' : '70',
		marginRight: '12',
		position: 'right',
	},
	brandSetting: {
		brandName: 'Licitatii Banci',
		brandSubTitle: 'Licitatiile bancilor din Romania la un singur loc',
		brandImg: 'https://www.licitatiibanci.ro/brand-logo.png',
		welcomeText:
			'Buna ziua,\nDoriti sa stiti mai multe despre un imobil de pe site? Ati mai participat la licitatii?',
		messageText:
			'Buna ziua, vreau mai multe informatii despre {{page_link}}',
		backgroundColor: '#0a5f54',
		ctaText: 'Incepe conversatia',
		borderRadius: '25',
		autoShow: false,
		phoneNumber: '40726414376',
	},
};
const url =
	'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?54029';
const scriptId = 'wati-integration';

const getWhatsAppWidget = () => {
	return document.querySelector('#whatsapp_chat_widget') as HTMLDivElement;
};

const hideWidget = () => {
	const element = getWhatsAppWidget();

	if (element) {
		element.style.display = 'none';
	}
};

const displayWidget = () => {
	const element = getWhatsAppWidget();

	if (element) {
		element.style.display = 'block';
	}
};

const routesWithoutWidget = ['/estates', '/cf'];

export const useWhatsAppWidget = () => {
	const location = useLocation();
	const active = useRef(true);

	const hideOnScroll = useCallback(() => {
		const appContent = document.querySelector(
			'#app-content'
		) as HTMLDivElement;

		if (!appContent) return;
		if (!active.current) return;

		const scroll = appContent.scrollTop || window.scrollY;

		if (scroll > 200) {
			hideWidget();
		} else {
			displayWidget();
		}
	}, []);

	useEffect(() => {
		const existingScript = document.getElementById(scriptId);

		if (existingScript) return;

		const script = document.createElement('script');
		script.src = url;
		script.async = true;
		script.id = scriptId;
		script.onload = () => {
			// @ts-ignore
			CreateWhatsappChatWidget(options);
		};
		document.body.appendChild(script);
	}, []);

	useEffect(() => {
		const shouldDisable = routesWithoutWidget.some((route) =>
			location.pathname.includes(route)
		);

		if (shouldDisable) {
			active.current = false;
			hideWidget();
		} else {
			active.current = true;
			hideOnScroll();
		}
	}, [location, hideOnScroll]);

	useEffect(() => {
		window.addEventListener('scroll', hideOnScroll);
		document
			.querySelector('#app-content')
			?.addEventListener('scroll', hideOnScroll);

		return () => {
			window.removeEventListener('scroll', hideOnScroll);
			document
				.querySelector('#app-content')
				?.removeEventListener('scroll', hideOnScroll);
		};
	}, [hideOnScroll, location]);
};
