import { ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, Badge, Button } from '@material-ui/core';
import {
	Favorite,
	Home,
	HomeWork,
	Landscape,
	Map,
	Payment,
	People,
} from '@material-ui/icons';
import { theme } from '../../theme';
import { useAuth } from '../../context/Auth';
import { useSavedSearchesCount } from '../../hooks/estate';
import { useMobileSize } from '../../hooks/responsive';
import { useAddAdvertLink } from '../../hooks/menu';
import { HeaderNotifications } from './Notifications';
import { UserRole } from 'foreclosure-types';

const useStylesLink = makeStyles(() => ({
	link: {
		textDecoration: 'none',
		[theme.breakpoints.up('md')]: {
			marginRight: theme.spacing(1),
		},
	},
	button: {
		textTransform: 'none',
		fontSize: '1rem',
	},
}));

const MenuLink = (props: {
	to?: LinkProps['to'];
	children: ReactNode;
	onClick?: () => any;
	startIcon?: ReactNode;
	style?: React.CSSProperties;
}) => {
	const classes = useStylesLink();
	const button = (
		<Button
			variant="text"
			startIcon={props.startIcon}
			className={classes.button}
		>
			{props.children}
		</Button>
	);

	if (props.to) {
		return (
			<Link
				to={props.to}
				className={classes.link}
				onClick={props.onClick}
				style={props.style}
			>
				{button}
			</Link>
		);
	}

	return (
		<span className={classes.link} style={props.style}>
			{button}
		</span>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		marginRight: theme.spacing(2),
	},
}));

export const replaceAuctionURL = (url: string, newBase: string) => {
	const baseToReplace = /investments|living/g;

	if (baseToReplace.test(url)) {
		return url.replace(baseToReplace, newBase);
	}

	return `/${newBase}`;
};

export const TopMenu = (props: { className?: string }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { logged } = useAuth();
	const { count } = useSavedSearchesCount();
	const { isMobile, isTablet } = useMobileSize();
	const { selfEstatesCount, ...addAdvertLink } = useAddAdvertLink();

	return (
		<div className={classes.container + ' ' + props.className || ''}>
			{isMobile && (
				<MenuLink to="/" startIcon={<Home />}>
					{t('top_menu.home')}
				</MenuLink>
			)}
			<MenuLink to="/subscription" startIcon={<Payment />}>
				{t('top_menu.subscription')}
			</MenuLink>
			{!selfEstatesCount && (
				<>
					<MenuLink
						to={replaceAuctionURL(pathname, 'investments')}
						startIcon={<Landscape />}
					>
						{t('top_menu.investments')}
					</MenuLink>
					<MenuLink
						to={replaceAuctionURL(pathname, 'living')}
						startIcon={<HomeWork />}
					>
						{t('top_menu.living')}
					</MenuLink>
				</>
			)}
			<MenuLink to="/cf" startIcon={<Map />}>
				{t('top_menu.locate_cf')}
			</MenuLink>
			{logged && (
				<MenuLink
					to="/search"
					startIcon={
						count > 0 ? (
							<Badge
								overlap="rectangular"
								badgeContent={count}
								color="primary"
							>
								<Favorite />
							</Badge>
						) : (
							<Favorite />
						)
					}
				>
					{t('search.savedTitle')}
				</MenuLink>
			)}
			{!!selfEstatesCount && (
				<MenuLink to="/admin/leads" startIcon={<People />}>
					{t('top_menu.leads')}
				</MenuLink>
			)}
			<MenuLink to={addAdvertLink.to} startIcon={addAdvertLink.icon}>
				{addAdvertLink.label}
			</MenuLink>
			{!isMobile && !isTablet && (
				<MenuLink style={{ marginRight: 0 }}>
					<HeaderNotifications />
				</MenuLink>
			)}
		</div>
	);
};

export const AdminTopMenu = (props: { className?: string }) => {
	const classes = useStyles();
	const { user } = useAuth();
	const isMainAdmin =
		user.email === 'admin@licitatiibanci.ro' &&
		user.role === UserRole.ADMIN;

	return (
		<div className={classes.container + ' ' + props.className || ''}>
			<MenuLink to="/admin/tasks">Task-uri</MenuLink>
			<MenuLink to="/admin/estates">Licitatii</MenuLink>
			<MenuLink to="/admin/leads">Leaduri</MenuLink>
			{isMainAdmin && <MenuLink to="/edit/users">Utilizatori</MenuLink>}
			<MenuLink to="/admin/comments">Comentarii</MenuLink>
			<MenuLink to="/admin/auction-owner">Organizatori</MenuLink>
			<MenuLink to="/admin/national-registry">Registru National</MenuLink>
			<MenuLink to="/cf">GeoPortal</MenuLink>
		</div>
	);
};
